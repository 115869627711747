import './index.css';
import './common/common.css';
import './Home/account.css';
import './CreateRequest/createReq.css';
import './Settings/settings.css';
import './Maintenance/maintenance.css';
import './chat/chat.css';

import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';
import Home from './Home/Home';
import Navbar from './common/Navbar';
import PageNotFound from './common/NotFound';
import Settings from './Settings/Settings';
import ReqDetailLoad from './Maintenance/ReqDetLoad';
import Footer from './common/Footer';
import CreateMaintenance from './Maintenance/CreateMaintenance';

function App() {
  return (
    <Router>
    <div className="App">
          <Navbar />
          <br />
          <div className="content">
          <Switch>
            <Route exact path="/">
              <Home />
            </Route>
            <Route exact path="/request/:id">
              <ReqDetailLoad />
            </Route>
            <Route path="/request">
            <CreateMaintenance />
            </Route>
            <Route path="/settings">
              <Settings />
            </ Route>
            <Route path='*'>
              <PageNotFound /> 
            </Route>
          </Switch>
          </div>
          <Footer />
    </div>
    </Router>
  );
} 

export default App;
