import { useParams } from "react-router";
import useFetch from "../useFetch";
import ReqDetail from "./ReqDetail";

const ReqDetailLoad = () => {
    const { id } = useParams();
    const { REACT_APP_SINGLE_MAINTENANCE_PATH } = process.env;
    const { data:request, error, isPending } = useFetch(REACT_APP_SINGLE_MAINTENANCE_PATH + 'maintid=' + id);
    
    return (

        <div className="request-detail-form">
        { error && <div>{ error }</div> }
        { isPending && <div>loading...</div> }
        { request && <ReqDetail request = { request } /> }
        </div>

    );
}
 
export default ReqDetailLoad;