import EmergencyReq from "./EmergencyReq";
import NewReqTile from "./NewReqTile";
import OpenReqLoad from "./OpenReqLoad";

const Maintenance = () => {
    return (
        <div className="maintenance">
            <div className="tile-container">
                <NewReqTile />
                <EmergencyReq />
            </div>
            <br />
            <div className="open-req-container">
                <OpenReqLoad />
            </div>
        </div>
    );
}
  
export default Maintenance; 