import useFetch from "../useFetch";




const AcctSummary = () => {

    const { REACT_APP_USER_PATH } = process.env;
    const { data:userArray, error, isPending } = useFetch( REACT_APP_USER_PATH);

    return (
        <div className="acctSummary">
            { isPending && <h2>Welcome!</h2>}
            { error && <h2>Error loading user: { error }</h2> }
            { userArray && <h2>Welcome, { userArray[0].fname } { userArray[0].lname }!</h2> }
            <p>Here is the status of your account. Today is </p> 
        </div>
    );
}
 
export default AcctSummary;