import { Link } from 'react-router-dom';
import LogIn from './LogIn';
import LogOut from './LogOut';
import { useCookies } from "react-cookie";

const Navbar = () => {
  const [cookies, setCookies] = useCookies();
  const loggedIn = cookies.loggedIn;
    return (
        <nav className="navbar">
            <Link to="/"><h1>ABL App</h1></Link>
            <div className="links">
            <Link to="/">Home</Link>
            <Link to="/request">New Request</Link>
            {/* <Link to="/settings">Settings</Link> */}
            { loggedIn ? <a href="https://api.rentablapp.com:8443/logout"><LogOut /></a> : <a href="https://api.rentablapp.com:8443/login"><LogIn /></a> }
            </div>
        </nav>
    );
}
 
export default Navbar;