import { Link } from "react-router-dom";

const NewReqTile = () => {
    return (
        <div className="new-request">  
            <Link to="request">
            <h3>Create Maintenance Request</h3>
            </Link>
        </div>
       
    );
}
 
export default NewReqTile;