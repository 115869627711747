import OpenRequests from "./OpenRequests";
import useFetch from "../useFetch";
import NoRequest from "./NoRequest";

const OpenReqLoad = () => {
    const { REACT_APP_MAINTENANCE_PATH } = process.env;
    const { data:requests, isPending, error } = useFetch(REACT_APP_MAINTENANCE_PATH);
    return (
        <div className="maintenance">
            <br />
            <h2>Open Requests</h2>
            <div className="open-req-container">

                {/* we need to figure out conditional render of requests and no requests below. */}

                { ( requests ) && ( <OpenRequests requests = { requests } /> ) }
                {/* { ( requests===[] ) && ( <NoRequest />) } */}
                { isPending && <div>Loading...</div>}
                { error && <div> { error } </div> }    

            </div>
        </div>
    );
}
  
export default OpenReqLoad; 