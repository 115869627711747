const PageNotFound = () => {
    return (
        <div className="not-found">
            <h3>Opps... our founder is apparently lazy</h3>
            <br />
            <p>He hasn't built this page yet, or maybe he never intended to.</p>
            <p>We invite you to decide his fate:</p>
            <br />
            <div className="home">
            <button><h4>Fire Him!</h4></button>
            <button><h4>Work him harder!</h4></button>
            </div>
        </div>
    );
} 
 
export default PageNotFound;