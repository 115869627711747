import { Link } from "react-router-dom";

const EmergencyReq = () => {
    return (
        <div className="emergency-req">
            <Link to="request">
            <h3>Create Emergency Request</h3>
            </Link>
        </div>
    );
}
 
export default EmergencyReq;