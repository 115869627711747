import { Link } from "react-router-dom";

const Footer = () => {
    return (
        <div className="footer">
            <div className="footer-wrapper">
            <div className="footer-internal">
                <ul>
                    <li><Link to="/">Home</Link></li>
                    <li><Link to="/about">About</Link></li>
                    <li><Link to="/maintenance">Maintenance</Link></li>
                </ul>
            </div>
            <div className="footer-external">
                <ul>
                    <li><a href="https://www.zillow.com/" target="_blank" rel="noreferrer">Zillow</a></li>
                    <li><a href="https://www.biggerpockets.com/" target="_blank" rel="noreferrer">Bigger Pockets</a></li>
                    <li><a href="https://www.biggerpockets.com/" target="_blank" rel="noreferrer">More Links</a></li>
                </ul>
            </div>
            </div>
        </div>
    );
}
 
export default Footer;