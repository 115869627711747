import Maintenance from "../Maintenance/Maintenance";
import AcctSummary from "./AcctSummary";

const Home = (props) => {


    return (
        <div>
            <div className="acct-container">
                <AcctSummary />
            </div>
                <Maintenance />
            <div className="home">

            </div>
        </div>

    );
}
 
export default Home;